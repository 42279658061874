<template>
  <div class="about-qii">
    <BContainer class="mt-5">
      <Card class="p-5">
        <CardHeading
          :heading="$t('aboutQii.heading')"
          class="mb-4"
          externalClass="about-qii__heading"
        />
        <p class="about-qii__description text-gray fw-medium mb-5">
          {{ $t("aboutQii.description") }}
        </p>
        <p class="fw-bold">
          {{ $t("aboutQii.questionsAboutQii") }}
        </p>

        <!-- Why Qii -->
        <div class="bg-light-green about-qii__why-qii p-5 mb-5">
          <CardHeading :heading="$t('aboutQii.whyQii.heading')" class="mb-4" />
          <ul class="fw-bold">
            <li
              class="mb-2 text-dark-gray"
              v-for="(point, i) in $t('aboutQii.whyQii.points')"
              :key="i"
            >
              {{ point }}
            </li>
          </ul>
        </div>

        <!-- Roadmap -->
        <div>
          <h4 class="fw-medium">{{ $t("aboutQii.roadmap.heading") }}</h4>
          <ol>
            <li v-for="(step, i) in $t('aboutQii.roadmap.steps')" :key="i">
              <h4>{{ step.heading }}</h4>

              <i18n
                :path="`aboutQii.roadmap.steps[${i}].points[${i}].texts[${idx}]`"
                v-for="(text, idx) in step.points[i].texts"
                :key="i + '-' + idx"
                tag="p"
              >
                <br />
              </i18n>
            </li>
          </ol>
        </div>
      </Card>
    </BContainer>
  </div>
</template>

<script>
import Card from "../components/general/Card.vue";
import CardHeading from "../components/general/CardHeading.vue";

export default {
  components: {
    Card,
    CardHeading,
  },
};
</script>

<style lang="scss" scoped>
.about-qii {
  &__description {
    max-width: 38.875rem;
  }
  &__why-qii {
    max-width: 44.125rem;
    ul {
      list-style-type: initial;
    }
  }
}
</style>
